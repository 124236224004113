<template>
  <div id="message-page">
    <div class="inner-acc">
      <div class="wrapper rowflex">
        <sidebar-account></sidebar-account>
        <article class="main">
          <div class="inner-chat">
            <div class="util">
              <h2>
                Chat
                <a
                  v-if="showAddNewChat"
                  href="#"
                  @click="newChat = true"
                  style="float: right"
                  >+</a
                >
              </h2>
              <form id="chatFrom" @submit.prevent="getConversation">
                <input
                  type="text"
                  v-model="search"
                  placeholder="Search User"
                  style="height: 32px"
                />
                <button type="submit"></button>
              </form>
              <div class="list">
                <template v-for="(room, index) in rooms">
                  <div
                    class="item"
                    :key="index"
                    @click="
                      {
                        conversationId = room.id;
                        newChat = false;
                      }
                    "
                    :class="{ 'active-chat': conversationId == room.id }"
                  >
                    <figure>
                      <img src="/img/dummy/default-profile.png" width="32" />
                    </figure>
                    <div class="caption">
                      <template
                        v-if="$store.state.userdata.account.admin === undefined"
                      >
                        <h3>
                          {{ room.adminAccount.admin.fullName | cut_string }}
                          <b>{{ room.neededRole }}</b>
                        </h3>
                      </template>
                      <template v-else>
                        <h3>
                          {{ room.userAccount.user.fullName | cut_string }}
                        </h3>
                      </template>
                      <p v-if="room.lastMessage !== undefined">
                        {{ room.lastMessage.message | shortChat }}
                      </p>
                      <div class="timing">
                        <span>{{ room.createdAt | date_month }}</span>
                        <!-- <b>1</b> -->
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="box" v-if="!newChat">
              <div
                class="scrollbar-inner"
                id="messagesContainer"
                ref="messagesContainer"
                v-chat-scroll
              >
                <template v-for="(chat, index) in chats">
                  <div
                    class="item"
                    :key="index"
                    :class="[chat.senderIsAdmin ? 'onleft' : 'onright']"
                  >
                    <p v-html="chat.message"></p>
                    <time class="read">
                      {{ chat.ISOTimestamp | timestamp_ampm }}
                    </time>
                  </div>
                </template>
              </div>
              <div class="input">
                <form @submit.prevent="sendMessage">
                  <input
                    type="text"
                    v-model="message"
                    placeholder="Enter your message"
                    required
                    :disabled="conversationId === null"
                  />
                  <button type="submit">Send</button>
                </form>
              </div>
            </div>
            <div class="box flexed" v-if="newChat">
              <div class="info">
                <div class="action">
                  <a
                    data-src="#popup-addprop"
                    data-fancybox
                    href="#"
                    class="button"
                    @click="targetChat = 'CSA'"
                  >
                    Contact CSA
                  </a>
                  <a
                    data-src="#popup-addprop"
                    data-fancybox
                    href="#"
                    class="button"
                    @click="targetChat = 'LO'"
                  >
                    Contact LO
                  </a>
                </div>
                <div class="hentry">
                  <h2>NEED HELP?</h2>
                  <p>Let us help you by clicking the button below</p>
                  <router-link to="/faq" class="button btn-hollow">
                    Help
                  </router-link>
                </div>
              </div>
              <!-- end of inf0 -->
            </div>
            <!--end of box -->
            <!--end of box -->
          </div>
          <!-- end of inner chat -->
        </article>
      </div>
      <!-- end of wrapper -->
      <div id="popup-addprop" class="popup-addprop" style="display: none;">
        <h3>
          Which Location Are You Interested In?
        </h3>
        <div class="fgroup" v-if="targetChat === 'CSA'">
          <fieldset>
            <select v-model="baseCity">
              <option></option>
              <option
                v-for="(city, index) in CSAcities"
                :key="index"
                :value="city"
              >
                {{ city }}
              </option>
              <option :value="0">Others</option>
            </select>
          </fieldset>
        </div>
        <div class="fgroup" v-if="targetChat === 'LO'">
          <fieldset>
            <select v-model="baseCity">
              <option></option>
              <option
                v-for="(city, index) in LOcities"
                :key="index"
                :value="city"
              >
                {{ city }}
              </option>
              <option :value="0">Others</option>
            </select>
          </fieldset>
        </div>
        <div class="toright">
          <button
            v-if="targetChat === 'CSA'"
            type="button"
            class="button close"
            :disabled="baseCity === null"
            @click="startChat('CSA')"
          >
            Start Chat With CSA
          </button>
          <button
            v-if="targetChat === 'LO'"
            type="button"
            class="button close"
            :disabled="baseCity === null"
            @click="startChat('LO')"
          >
            Start Chat With LO
          </button>
        </div>
      </div>
    </div>
    <!-- end of inner mort -->
  </div>
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";

export default {
  name: "Message",
  metaInfo: {
    title: "Message"
  },
  components: {
    SidebarAccount
  },
  data() {
    return {
      rooms: [],
      chats: [],
      conversationId: null,
      search: null,
      message: null,
      targetChat: null,
      baseCity: null,
      CSAcities: [],
      LOcities: [],
      newChat: false
    };
  },
  watch: {
    conversationId() {
      this.getListChat();
    }
  },
  computed: {
    showAddNewChat() {
      const findCSA = this.rooms.findIndex(item => item.neededRole === "CSA");
      const findLO = this.rooms.findIndex(item => item.neededRole === "LO");
      return findCSA < 0 || findLO < 0;
    }
  },
  filters: {
    shortChat(string) {
      return string.length > 50 ? string.substring(0, 50) + "..." : string;
    }
  },
  mounted() {
    this.getConversation();
    this.getAvailableCities("CSA");
    this.getAvailableCities("LO");

    const socket = new WebSocket(
      `${this.socket_url}?token=${this.$store.state.userdata.accessToken}`
    );
    socket.addEventListener("open", event => {
      console.log("Hello Server!");
      console.log(event);
    });
    socket.addEventListener("message", event => {
      console.log(event.data);
      const data = event.data.split(".").pop();
      console.log(JSON.parse(data));
      if (this.conversationId) {
        this.getListChat();
      }
    });
  },
  methods: {
    getConversation() {
      this.$axios
        .get(`/conversations`, {
          params: {
            page: 1,
            limit: 20,
            sortBy: "id.desc",
            search: this.search
          }
        })
        .then(response => {
          this.rooms = response.data.data.rows;
          if (this.$route.query.conversation !== undefined) {
            this.conversationId = this.$route.query.conversation;
            this.newChat = false;
          }
          if (this.rooms.length === 0) {
            this.newChat = true;
          }
        });
    },
    getListChat() {
      this.$axios
        .get(`/conversations/${this.conversationId}/messages`)
        .then(response => {
          this.chats = response.data.data;
        });
    },
    sendMessage() {
      this.$axios
        .post(`/chat`, {
          conversationId: this.conversationId,
          message: this.message
        })
        .then(() => {
          this.message = null;
          document.getElementById("chatFrom").reset();
          this.getListChat();
        });
    },
    getAvailableCities(type) {
      this.$axios
        .get(`/misc/available-cities`, {
          params: {
            role: type
          }
        })
        .then(response => {
          if (type === "CSA") {
            this.CSAcities = response.data.data;
          } else {
            this.LOcities = response.data.data;
          }
        });
    },
    startChat(type) {
      const url =
        type === "CSA" ? "/conversations/init-csa" : "/conversations/init-lo";
      this.$axios
        .post(url, {
          baseCity: this.baseCity === 0 ? null : this.baseCity
        })
        .then(response => {
          window.location.href = `/account/message?conversation=${response.data.data.id}`;
        });
    }
  }
};
</script>

<style scoped>
.item {
  margin-top: 16px;
}
.scrollbar-inner {
  overflow: auto;
  max-height: 500px !important;
}
.active-chat {
  background-color: aliceblue !important;
}
</style>
